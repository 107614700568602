@import url('https://fonts.googleapis.com/css2?family=Pontano+Sans&family=Stint+Ultra+Expanded&display=swap');

:root {
  --bg-primary: 24, 115, 195;
  --mdc-theme-primary: rgb(var(--bg-primary));
}

* {
  box-sizing: border-box;
  font-family: 'Pontano Sans', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

a {
  color: var(--mdc-theme-primary);
  text-decoration: none;
}

a:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

body {
  background-color: whitesmoke;
}

html {
  scroll-behavior: smooth;
}

p {
  text-indent: 10%;
  margin-block-end: 1rem;
}

p>a:hover,
p>a:focus {
  color: black;
  background-color: rgba(var(--bg-primary), 0.5);
}

section {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 3rem 10%;
}

section * {
  max-width: 90rem;
}

.avatar {
  border-radius: 50%;
  height: 10rem;
  width: 10rem;
  margin-block: 1rem;
}

.projects>div {
  margin-block-end: 1rem;
}

.social {
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
}

#contact form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
}

#contact form>div,
#contact form>button {
  margin-block-end: 1rem;
}

#about {
  padding-block-start: 5rem;
}

#work {
  background-color: rgba(var(--bg-primary), 0.25);
}

@media only screen and (min-width: 900px) {
  .projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-block-start: 3rem;
    width: 100%;
  }

  .projects>div {
    width: 45%;
  }
}
